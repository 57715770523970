import Background from '../components/layout/Background'
import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'
import ContentBuilder from '../components/ContentBuilder'
import LetsDiscusCTABlock from '../components/LetsDiscusCTABlock'
import MetaTags from '../components/MetaTags'

export { getServerSideProps } from '../graphql/page'

function Page( { entry: page } ) {
  const heroContent = page.hero[ 0 ]

  return (
    <AppChrome>
      <MetaTags
        url={ `/${ page.slug }` }
        title={ page.title }
      />
      <Background $color="grey9">
        <Hero 
          title={ heroContent.heroTitle }
          subheading={ heroContent.subtitle }
          html={ heroContent.body }
          bottomPad="small"
        />
        <ContentBuilder 
          content={ page.contentBuilder } 
        />
        <LetsDiscusCTABlock />
      </Background>
    </AppChrome>
  )
}

export default Page
